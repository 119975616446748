import { PortableText } from "@portabletext/react";
import dayjs from "dayjs";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import toast from "react-hot-toast";
import thumbnail from "../assets/thumbnail.jpg";
import Button from "../components/Button";
import MyLink from "../components/Link";

const Footer = ({ footer, data, news }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const toastId = toast.loading("Submitting...");
    const d = {
      data: {
        name: name,
        email: email,
        date: new Date().toUTCString(),
        website: window.location.origin,
      },
      sheetID: 53,
    };

    fetch("https://leads.civsav.com/template/contact", {
      method: "POST",
      body: JSON.stringify(d),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then(() => {
        if (typeof window !== "undefined" && window.dataLayer) {
          window.dataLayer.push({
            event: "generate_lead",
          });
        }
        toast.dismiss(toastId);
        toast.success("Request has been submitted successfully");
        setLoading(false);
        setName("");
        setEmail("");
      })
      .catch(() => {
        toast.error("Something went wrong! Please try again");
        setLoading(false);
        setName("");
        setEmail("");
      });
  };
  return (
    <footer className="footer-default footer-white secondary-bg">
      <div className="container">
        <div className="footer-top wow fadeInUp">
          <div className="row">
            <div className="col-lg-4 col-md-12 footer-contact-item">
              <div className="contact-info d-flex justify-content-center">
                <div className="site-logo text-center">
                  <Link to="/">
                    <a className="brand-logo">
                      <GatsbyImage
                        image={data?.logo?.asset.gatsbyImageData}
                        alt={data?.logo?.alt}
                        objectFit="cover"
                        style={{
                          width: "130px",
                          objectFit: "cover",
                        }}
                      />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 footer-contact-item">
              <div className="contact-info d-flex">
                <div className="icon">
                  <i className="flaticon-placeholder" />
                </div>
                <div className="text">
                  <h5>Locations</h5>
                  <h6>{data?.location}</h6>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 footer-contact-item">
              <div className="contact-info d-flex">
                <div className="icon">
                  <i className="flaticon-email" />
                </div>
                <div className="text">
                  <h5>Email Us</h5>
                  <h6>
                    <a href={`mailto:${data?.email}`}>{data?.email}</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-widget pt-70 pb-25">
          <FooterMenu
            menu={data?.menu}
            about={data?.about}
            social={data?.social}
            news={data.news}
          />
        </div>
        {data?.newsletter && data?.newsletter.enable && (
          <div className="footer-newsletter footer-newsletter-one text-white wow fadeInUp">
            <div className="row">
              <div className="col-xl-3">
                <div className="footer-text">
                  <h5>{data?.newsletter.heading}</h5>
                </div>
              </div>
              <div className="col-xl-9">
                <div className="newsletter-form">
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form_group">
                          <input
                            type="text"
                            className="form_control"
                            placeholder="Name"
                            name="name"
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="form_group">
                          <input
                            type="email"
                            className="form_control"
                            placeholder="Email Address"
                            name="email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form_group">
                          {data?.newsletter?.button?.title && (
                            <Button
                              text={data.newsletter?.button.title}
                              btnType={data?.newsletter?.button.btnType}
                              link={data?.newsletter?.button.link}
                              file={data?.newsletter?.button.file}
                              linkType={data?.newsletter?.button.linkType}
                              submit={true}
                              disable={loading}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="footer-copyright">
          <div className="col-lg-12">
            <div className="copyright-text text-center">
              {/* <a href="https://www.civsav.com" target={"_blank"}>
                <p>© 2023 Civilized Savage. All Rights Reserved</p>
              </a> */}
              {data?._rawCc && <PortableText value={data?._rawCc} />}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;

const Footer3 = () => (
  <footer
    className="footer-bg bg_cover p-r z-1 footer-white"
    style={{ backgroundImage: "url(/assets/images/bg/footer-bg-1.jpg)" }}
  >
    <div className="container">
      <div className="footer-newsletter footer-newsletter-two yellow-bg">
        <div className="row">
          <div className="col-xl-3">
            <div className="footer-text">
              <h5>Subscrive Our Newsletter To Get More Updates</h5>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="newsletter-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-lg-5">
                    <div className="form_group">
                      <input
                        type="email"
                        className="form_control"
                        placeholder="Email Address"
                        name="email"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form_group">
                      <input
                        type="text"
                        className="form_control"
                        placeholder="Phone"
                        name="phone"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form_group">
                      <button className="main-btn bordered-btn">
                        Subscribe Now
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-widget pb-25">
        <FooterMenu />
      </div>
      <div className="footer-copyright border-top-white-1">
        <div className="col-lg-12">
          <div className="copyright-text text-center">
            <a href="https://www.civsav.com" target={"_blank"}>
              <p>© 2023 Civilized Savage. All Rights Reserved</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

const Footer4 = () => (
  <footer className="footer-default footer-white secondary-bg">
    <div className="container">
      <div className="footer-newsletter footer-newsletter-two yellow-bg">
        <div className="row">
          <div className="col-xl-3">
            <div className="footer-text">
              <h5>Subscrive Our Newsletter To Get More Updates</h5>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="newsletter-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="col-lg-5">
                    <div className="form_group">
                      <input
                        type="email"
                        className="form_control"
                        placeholder="Email Address"
                        name="email"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form_group">
                      <input
                        type="text"
                        className="form_control"
                        placeholder="Phone"
                        name="phone"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form_group">
                      <button className="main-btn bordered-btn">
                        Subscribe Now
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-widget pb-25">
        <FooterMenu />
      </div>
      <div className="footer-copyright border-top-white-1">
        <div className="col-lg-12">
          <div className="copyright-text text-center">
            <a href="https://www.civsav.com" target={"_blank"}>
              <p>© 2023 Civilized Savage. All Rights Reserved</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

const FooterMenu = ({ about, menu, social, news }) => (
  <div className="row">
    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
      <div className="footer-widget about-widget mb-[40px] wow fadeInDown">
        <h4 className="widget-title">About Us</h4>
        <div className="footer-about-content">
          <p>{about}</p>
          <div className="social-box">
            <h4 className="mb-15">Follow On</h4>
            <ul className="social-link">
              {social?.map((item, index) => (
                <li key={index}>
                  <a href={item.url}>
                    <i className={`fab ${item.icon}`} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-lg-4 col-md-7 col-sm-12">
      <div className="footer-widget footer-nav-widget mb-[40px] wow fadeInUp">
        <h4 className="widget-title">Services</h4>
        <div className="footer-widget-nav">
          <ul>
            {menu?.slice(0, 7).map((item, index) => (
              <li key={index}>
                <MyLink
                  link={item.link}
                  linkType={item.type}
                  text={item.label}
                />
              </li>
            ))}
          </ul>
          {menu?.length > 7 && (
            <ul>
              {menu?.slice(7).map((item, index) => (
                <li key={index}>
                  <MyLink
                    link={item.link}
                    linkType={item.type}
                    text={item.label}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
    {news.length > 0 && (
      <div className="col-lg-4 col-lg-4 col-md-6 col-sm-12">
        <div className="footer-widget recent-post-widget mb-[40px] wow fadeInDown">
          <h4 className="widget-title">Recent News</h4>
          <ul className="post-widget-wrap">
            {news?.map((node, index) => {
              return (
                <li className="post-item" key={index}>
                  <img
                    src={
                      node.thumbnail?.asset
                        ? node?.thumbnail?.asset?.url
                        : thumbnail
                    }
                    alt="Post Image"
                  />
                  <div className="post-title-date">
                    <h3 className="title">
                      <Link to={`/blog/${node?.slug?.current}`}>
                        <a>{node.title}</a>
                      </Link>
                    </h3>
                    <span className="posted-on">
                      <i className="fas fa-calendar-alt" />
                      <a> {dayjs(node.publishedAt).format("DD MMMM YYYY")} </a>
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <Link to="/blog">
            <a className="more-btn">View More News</a>
          </Link>
        </div>
      </div>
    )}
  </div>
);
