exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-about-kundel-inventions-jsx": () => import("./../../../src/pages/about-kundel-inventions.jsx" /* webpackChunkName: "component---src-pages-about-kundel-inventions-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-frequently-asked-questions-jsx": () => import("./../../../src/pages/frequently-asked-questions.jsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-jsx" */),
  "component---src-pages-get-quote-jsx": () => import("./../../../src/pages/get-quote.jsx" /* webpackChunkName: "component---src-pages-get-quote-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-project-gallery-jsx": () => import("./../../../src/pages/project-gallery.jsx" /* webpackChunkName: "component---src-pages-project-gallery-jsx" */),
  "component---src-pages-safety-maintenance-jsx": () => import("./../../../src/pages/safety-maintenance.jsx" /* webpackChunkName: "component---src-pages-safety-maintenance-jsx" */),
  "component---src-pages-trucking-delivery-jsx": () => import("./../../../src/pages/trucking-delivery.jsx" /* webpackChunkName: "component---src-pages-trucking-delivery-jsx" */),
  "component---src-templates-blog-details-jsx": () => import("./../../../src/templates/blog-details.jsx" /* webpackChunkName: "component---src-templates-blog-details-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../../../src/templates/blog-list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-blogs-category-jsx": () => import("./../../../src/templates/blogs-category.jsx" /* webpackChunkName: "component---src-templates-blogs-category-jsx" */),
  "component---src-templates-blogs-tag-jsx": () => import("./../../../src/templates/blogs-tag.jsx" /* webpackChunkName: "component---src-templates-blogs-tag-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

